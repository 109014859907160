@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0%;
  font-family: "Poppins", sans-serif !important;
  box-sizing: border-box !important;
  padding: 0%;
}
@media print {
  html,
  body {
    display: none; /* hide whole page */
  }
}
::-webkit-scrollbar {
  width: 0;
}
.customSidebarList::-webkit-scrollbar {
  width: 0px;
}

.customSidebarList::-webkit-scrollbar-track {
  background: #fff;
}

.customSidebarList::-webkit-scrollbar-thumb {
  background: linear-gradient(
    91.18deg,
    #3bc6f1 1.39%,
    #87e3ff 53.43%,
    #3bc6f1 99.58%
  );
  border-radius: 50px;
}

.spinner {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #668B4B 94%, #0000) top/9px 9px
      no-repeat,
    conic-gradient(#0000 30%, #668B4B);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
  animation: spinner-c7wet2 1s infinite linear;
}

@keyframes spinner-c7wet2 {
  100% {
    transform: rotate(1turn);
  }
}

#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

body {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  margin: 0px;
  height: 450px;
  width: 450px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(234, 231, 231);
}
.termsCondtionContent {
  border: none;
}
.termsCondtionContent .ql-toolbar {
  border: none;
  padding: 16px 16px;
  background: #3bc6f1;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border: 1px solid grey;
}
.termsCondtionContent .ql-toolbar .ql-formats button {
  width: 30px;
  height: 30px;
}
.termsCondtionContent .ql-container {
  border: 1px solid grey;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.termsCondtionContent .ql-container .ql-editor {
  min-height: 300px;
  overflow-y: auto;
  height: 200px;
}
